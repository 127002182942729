import {connect} from 'react-redux';

import Login from './Login';
import {
	check,
	login,
	logout,
	register,
	restore,
	resendVerification
} from '../../actions/authorization';

const mapStateToProps = (state, ownProps) => ({
	...ownProps,
	authorized: state.authorization.authorized,
	loggedIn: state.authorization.loggedIn,
	loggedOut: state.authorization.loggedOut,
	registered: state.authorization.registered,
	restored: state.authorization.restored,
	verificationResent: state.authorization.verificationResent,
	loading: state.authorization.status.loading
});

const mapDispatchToProps = (dispatch) => ({
	check: () => dispatch(check()),
	login: (email, password) => dispatch(login(email, password)),
	logout: () => dispatch(logout()),
	register: (data) => dispatch(register(data)),
	restore: (email) => dispatch(restore(email)),
	resendVerification: (email) => dispatch(resendVerification(email))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Login);
