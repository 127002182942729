import update from 'immutability-helper';

import {Notification} from '../util/actionTypes';

const initialState = {
	notifications: []
};

const notification = (state = initialState, action) => {
	switch (action.type) {
		case Notification.Add:
			return update(state, {
				notifications: {$push: action.notification}
			});
		case Notification.Remove:
			return {
				...state,
				notifications: state.notifications.filter((n) => n.id !== action.id)
			};
		default:
			return state;
	}
};

export default notification;
