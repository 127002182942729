import React from 'react';

import Talk from '../../component/Talk';
import Layout from '../../component/Layout';

const Home = () => (
	<Layout active="home">
		<Talk className="talk" />
	</Layout>
);

export default Home;
