import React from 'react';

import Layout from '../../component/Layout';

function Knowledge() {
	return (
		<Layout active="knowledge">
			<i />
		</Layout>
	);
}

export default Knowledge;
