import axios from 'axios';

import {Contact} from '../util/actionTypes';

export const formSubmit = (data) => (dispatch) => {
	dispatch({
		type: Contact.FormSubmit.REQUEST
	});

	axios.post(`${process.env.REACT_APP_API_URL}/open/contact`, data)
		.then((payload) => {
			console.log(payload);

			if (payload.success) {
				dispatch({
					type: Contact.FormSubmit.SUCCESS
				});
			} else {
				dispatch({
					type: Contact.FormSubmit.FAILURE
				});
			}
		})
		.catch((error) => {
			dispatch({
				type: Contact.FormSubmit.FAILURE
			});

			console.log('formSubmit error', error);
		});
};
