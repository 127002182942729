import React from 'react';
import PropTypes from 'prop-types';

const Example = (props) => {
	const {title, text} = props;

	return (
		<>
			{
				title && <h1>{title}</h1>
			}
			<p>{text}</p>
		</>
	);
};

Example.propTypes = {
	title: PropTypes.string,
	text: PropTypes.string.isRequired
};

Example.defaultProps = {
	title: null
};

export default Example;
