import {Authorization} from '../util/actionTypes';
import {DEFAULT_STATUS} from '../util/types';

const initialState = {
	authorized: false,
	loggedIn: false,
	loggedOut: false,
	signedIn: false,
	registered: false,
	restored: false,
	verificationResent: false,
	verified: false,
	status: DEFAULT_STATUS
};

const authorization = (state = initialState, action) => {
	switch (action.type) {
		case Authorization.Login.REQUEST:
			return {
				...state,
				loggedIn: false,
				authorized: false,
				status: {
					loading: true,
					error: false
				}
			};
		case Authorization.Login.SUCCESS:
			return {
				...state,
				loggedIn: true,
				authorized: true,
				status: DEFAULT_STATUS
			};
		case Authorization.Login.FAILURE:
			return {
				...state,
				status: {
					loading: false,
					error: true
				}
			};
		case Authorization.Check.REQUEST:
			return {
				...state,
				status: {
					loading: true,
					error: false
				}
			};
		case Authorization.Check.SUCCESS:
			return {
				...state,
				authorized: true,
				status: DEFAULT_STATUS
			};
		case Authorization.Check.FAILURE:
			return {
				...state,
				authorized: false,
				status: {
					loading: false,
					error: true
				}
			};
		case Authorization.Logout.REQUEST:
			return {
				...state,
				loggedOut: false,
				status: {
					loading: true,
					error: false
				}
			};
		case Authorization.Logout.SUCCESS:
			return {
				...state,
				loggedOut: true,
				authorized: false,
				status: DEFAULT_STATUS
			};
		case Authorization.Logout.FAILURE:
			return {
				...state,
				status: {
					loading: false,
					error: true
				}
			};
		case Authorization.SignIn.REQUEST:
			return {
				...state,
				signedIn: false,
				status: {
					loading: true,
					error: false
				}
			};
		case Authorization.SignIn.SUCCESS:
			return {
				...state,
				signedIn: true,
				status: DEFAULT_STATUS
			};
		case Authorization.SignIn.FAILURE:
			return {
				...state,
				status: {
					loading: false,
					error: true
				}
			};
		case Authorization.Register.REQUEST:
			return {
				...state,
				registered: false,
				status: {
					loading: true,
					error: false
				}
			};
		case Authorization.Register.SUCCESS:
			return {
				...state,
				registered: true,
				status: DEFAULT_STATUS
			};
		case Authorization.Register.FAILURE:
			return {
				...state,
				status: {
					loading: false,
					error: true
				}
			};
		case Authorization.Restore.REQUEST:
			return {
				...state,
				restored: false,
				status: {
					loading: true,
					error: false
				}
			};
		case Authorization.Restore.SUCCESS:
			return {
				...state,
				restored: true,
				status: DEFAULT_STATUS
			};
		case Authorization.Restore.FAILURE:
			return {
				...state,
				status: {
					loading: false,
					error: true
				}
			};
		case Authorization.ResendVerification.REQUEST:
			return {
				...state,
				verificationResent: false,
				status: {
					loading: true,
					error: false
				}
			};
		case Authorization.ResendVerification.SUCCESS:
			return {
				...state,
				verificationResent: true,
				status: DEFAULT_STATUS
			};
		case Authorization.ResendVerification.FAILURE:
			return {
				...state,
				status: {
					loading: false,
					error: true
				}
			};
		case Authorization.Verify.REQUEST:
			return {
				...state,
				verified: false,
				status: {
					loading: true,
					error: false
				}
			};
		case Authorization.Verify.SUCCESS:
			return {
				...state,
				verified: true,
				status: DEFAULT_STATUS
			};
		case Authorization.Verify.FAILURE:
			return {
				...state,
				status: {
					loading: false,
					error: true
				}
			};
		default:
			return state;
	}
};

export default authorization;
