import React from 'react';
import PropTypes from 'prop-types';

const Tabs = (props) => {
	let {children} = props;
	const {active, handleChange} = props;

	children = Array.isArray(children) ? children : [children];

	return (
		<div className="tabs">
			<div className="header fit-screen">
				{
					children.map((c) => (
						<button
							key={c.props.label}
							type="button"
							onClick={() => handleChange(c.props.label)}
						>
							<i />
							<h1>
								{c.props.label}
							</h1>
							<h6>
								{c.props.text}
							</h6>
						</button>
					))
				}
			</div>
			{
				active && (
					<div className="content">
						{
							children.find((c) => c.props.label === active)
						}
					</div>
				)
			}
		</div>
	);
};

Tabs.propTypes = {
	children: PropTypes.node.isRequired,
	active: PropTypes.string,
	handleChange: PropTypes.func.isRequired
};

Tabs.defaultProps = {
	active: null
};

export default Tabs;
