import React, {Component} from 'react';
import PropTypes from 'prop-types';

import Layout from '../../component/Layout';

class Verify extends Component {
	componentDidMount() {
		console.log('here'); // eslint-disable-line
		console.log(this.props.code); // eslint-disable-line
	}

	render() {
		return (
			<Layout active="home" />
		);
	}
}

Verify.propTypes = {
	code: PropTypes.string.isRequired
};

export default Verify;
