import React from 'react';

import Layout from '../../component/Layout';

function Fundation() {
	return (
		<Layout active="fundation">
			<i />
		</Layout>
	);
}

export default Fundation;
