import React from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const Modal = (props) => {
	const {
		title,
		className,
		children
	} = props;

	return ReactDOM.createPortal((
		<div className={classNames('modal', className)}>
			<div className="modal-wrapper">
				<div className="modal-content">
					<p className="title">{title}</p>
					<div className="content">
						{children}
					</div>
				</div>
			</div>
		</div>
	), document.getElementById('modals'));
};

Modal.propTypes = {
	title: PropTypes.string.isRequired,
	className: PropTypes.string,
	children: PropTypes.node.isRequired,
	footer: PropTypes.string
};

Modal.defaultProps = {
	className: '',
	footer: ''
};

export default Modal;
