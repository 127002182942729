import axios from 'axios';

import {Authorization} from '../util/actionTypes';

export const login = (email, password) => (dispatch) => {
	dispatch({
		type: Authorization.Login.REQUEST
	});

	const data = {
		email,
		password
	};

	axios.post(`${process.env.REACT_APP_API_URL}/auth/login`, data)
		.then((payload) => {
			console.log(payload);

			if (payload.success) {
				dispatch({
					type: Authorization.Login.SUCCESS
				});

				window.localStorage.setItem('token', payload.message);
			} else {
				dispatch({
					type: Authorization.Login.FAILURE
				});
			}
		})
		.catch((error) => {
			dispatch({
				type: Authorization.Login.FAILURE
			});

			console.log('login error', error);
		});
};

export const logout = () => (dispatch) => {
	dispatch({
		type: Authorization.Logout.REQUEST
	});

	if (window.localStorage.getItem('token') === null) {
		dispatch({
			type: Authorization.Logout.FAILURE
		});
	} else {
		try {
			window.localStorage.removeItem('token');

			dispatch({
				type: Authorization.Logout.SUCCESS
			});
		} catch (e) {
			dispatch({
				type: Authorization.Logout.FAILURE
			});
		}
	}
};

export const check = () => (dispatch) => {
	dispatch({
		type: Authorization.Check.REQUEST
	});

	axios.get(`${process.env.REACT_APP_API_URL}/auth/check`)
		.then((payload) => {
			console.log(payload);

			if (payload.success) {
				dispatch({
					type: Authorization.Check.SUCCESS
				});

				window.localStorage.setItem('token', payload.message);
			} else {
				dispatch({
					type: Authorization.Check.FAILURE
				});

				window.localStorage.removeItem('token');
			}
		})
		.catch(() => {
			dispatch({
				type: Authorization.Check.FAILURE
			});

			window.localStorage.removeItem('token');
		});
};

export const register = (data) => (dispatch) => {
	dispatch({
		type: Authorization.Register.REQUEST
	});

	axios.post(`${process.env.REACT_APP_API_URL}/auth/register`, data)
		.then((payload) => {
			console.log(payload);

			if (payload.success) {
				dispatch({
					type: Authorization.Register.SUCCESS
				});
			} else {
				dispatch({
					type: Authorization.Register.FAILURE
				});
			}
		})
		.catch((error) => {
			dispatch({
				type: Authorization.Register.FAILURE
			});

			console.log('register error', error);
		});
};

export const restore = (email) => (dispatch) => {
	dispatch({
		type: Authorization.Restore.REQUEST
	});

	const data = {
		email
	};

	axios.post(`${process.env.REACT_APP_API_URL}/auth/restore`, data)
		.then((payload) => {
			console.log(payload);

			if (payload.success) {
				dispatch({
					type: Authorization.Restore.SUCCESS
				});
			} else {
				dispatch({
					type: Authorization.Restore.FAILURE
				});
			}
		})
		.catch((error) => {
			dispatch({
				type: Authorization.Restore.FAILURE
			});

			console.log('couldnt restore', error);
		});
};

export const verify = (code) => (dispatch) => {
	dispatch({
		type: Authorization.Verify.REQUEST
	});

	axios.post(`${process.env.REACT_APP_API_URL}/auth/verify/${code}`)
		.then((payload) => {
			console.log(payload);

			if (payload.success) {
				dispatch({
					type: Authorization.Verify.SUCCESS
				});
			} else {
				dispatch({
					type: Authorization.Verify.FAILURE
				});
			}
		})
		.catch((error) => {
			dispatch({
				type: Authorization.Verify.FAILURE
			});

			console.log('couldnt verify', error);
		});
};

export const resendVerification = (email) => (dispatch) => {
	dispatch({
		type: Authorization.ResendVerification.REQUEST
	});

	const data = {
		email
	};

	axios.post(`${process.env.REACT_APP_API_URL}/auth/resend_verification`, data)
		.then((payload) => {
			console.log(payload);

			if (payload.success) {
				dispatch({
					type: Authorization.ResendVerification.SUCCESS
				});
			} else {
				dispatch({
					type: Authorization.ResendVerification.FAILURE
				});
			}
		})
		.catch((error) => {
			dispatch({
				type: Authorization.ResendVerification.FAILURE
			});

			console.log('couldnt send verification', error);
		});
};
