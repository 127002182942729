import React, {Component} from 'react';
import PropTypes from 'prop-types';
import shortid from 'shortid';
import classNames from 'classnames';

class Input extends Component {
	state = {
		error: null
	};

	constructor(props) {
		super(props);

		this.input = React.createRef();
	}

	componentDidMount() {
		const {handleError, name} = this.props;

		if (handleError) {
			const error = !this.input.current.checkValidity();
			handleError(name, error);
		}
	}

	componentDidUpdate(prevProps) {
		if (!prevProps.refreshError && this.props.refreshError && this.props.handleError) {
			const error = !this.input.current.checkValidity();

			this.setState({
				error
			});

			this.props.handleError(this.props.name, error);
		}
	}

	handleChange = (e) => {
		const {handleChange, handleError, name} = this.props;
		const {value} = e.target;

		handleChange(name, value);

		const error = !e.target.checkValidity();

		if (handleError) {
			this.setState({
				error
			});

			handleError(name, error);
		}
	}

	render() {
		const {
			id: propId,
			type,
			placeholder,
			name,
			value,
			required,
			pattern,
			inlineLabel,
			label
		} = this.props;

		const id = propId || shortid.generate();

		const {error} = this.state;

		const divClass = classNames('form-control', {'inline-label': inlineLabel, error: error === true, validated: error === false});

		return (
			<div className={divClass}>
				<div>
					{
						label && (
							<label htmlFor={id}>{label}</label>
						)
					}
					<input
						ref={this.input}
						id={id}
						name={name}
						type={type}
						placeholder={placeholder}
						value={value}
						onChange={this.handleChange}
						onBlur={this.handleBlur}
						pattern={pattern}
						required={required}
					/>
				</div>
			</div>
		);
	}
}

Input.propTypes = {
	id: PropTypes.string,
	name: PropTypes.string.isRequired,
	value: PropTypes.string.isRequired,
	type: PropTypes.oneOf(['text', 'date', 'password', 'tel', 'email', 'number']),
	placeholder: PropTypes.string,
	required: PropTypes.bool,
	label: PropTypes.string,
	inlineLabel: PropTypes.bool,
	pattern: PropTypes.string,
	handleChange: PropTypes.func.isRequired,
	handleError: PropTypes.func,
	refreshError: PropTypes.bool
};

Input.defaultProps = {
	id: null,
	type: 'text',
	placeholder: '',
	label: null,
	inlineLabel: false,
	required: false,
	pattern: null,
	handleError: null,
	refreshError: false
};

export default Input;
