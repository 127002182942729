import {connect} from 'react-redux';

import Notifications from './Notifications';
import {removeNotification} from '../../../actions/notification';

const mapStateToProps = (state) => ({
	notifications: state.notification.notifications
});

const mapDispatchToProps = (dispatch) => ({
	removeNotification: (id) => dispatch(removeNotification(id))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Notifications);
