export const StepType = {
	SIGNIN: 'SIGNIN',
	RESTORE: 'RESTORE',
	RESET: 'RESET',
	SIGNUP_CHOOSE: 'SIGNUP_CHOOSE',
	SIGNUP_AS_THERAPIST: 'SIGNUP_AS_THERAPIST',
	SIGNUP_AS_CLIENT: 'SIGNUP_AS_CLIENT',
	CONFIRM: 'CONFIRM'
};

export const DEFAULT_STATUS = {
	loading: false,
	error: false
};

export const AccountTypeName = {
	THERAPIST: 'THERAPIST',
	CLIENT: 'CLIENT'
};
