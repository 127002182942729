import React, {Component} from 'react';
import {Link} from 'react-router-dom';

import Layout from '../../../component/Layout';
import {Form, Input} from '../../../component/Form';
import {handleChange, handleError} from '../../../util/common';

const aboutEntries = [
	{
		title: 'ZAŁÓŻ KONTO',
		subtitle: 'Zarejestruj się jako specjalista.',
		image: `${process.env.PUBLIC_URL}/static/images/account.png`
	},
	{
		title: 'WYPEŁNIJ PROFIL',
		subtitle: 'Dodaj swój opis, zdjęcie, wykształcenie. Napisz czym się zajmujesz.',
		image: `${process.env.PUBLIC_URL}/static/images/survey.png`
	},
	{
		title: 'DODAJ SWÓJ CENNIK',
		subtitle: 'W tell.well to Ty decydujesz ile kosztują rozmowy z Tobą.',
		image: `${process.env.PUBLIC_URL}/static/images/date.png`
	},
	{
		title: 'UZUPEŁNIJ KALENDARZ',
		subtitle: 'Ty decydujesz kiedy i w jakich godzinach ejsteś dostępny dla swoich klientów.',
		image: `${process.env.PUBLIC_URL}/static/images/people.png`
	},
	{
		title: 'BĄDŹ ONLINE',
		subtitle: (
			<>
				Wykorzystaj swój czas.
				<br />
				W każdym momencie możesz być również dostępny online dla osób które chcą porozmawiać.
			</>
		),
		image: `${process.env.PUBLIC_URL}/static/images/card.png`
	},
	{
		title: 'ROZMAWIAJ',
		subtitle: (
			<>
				Nie potrzebujesz instalować dodatkowych programów.
				<br />
				Rozmawiasz bezpiecznie za pośrednictwem tej strony w formie audio lub/i video.
			</>
		),
		image: `${process.env.PUBLIC_URL}/static/images/call.png`
	}
];

class AboutSpec extends Component {
	state = {
		email: ''
	};

	handleChange = (name, value) => handleChange(this, name, value);

	handleError = (name, error) => handleError(this, name, error);

	render() {
		const {
			email
		} = this.state;

		return (
			<Layout active="about">
				<div className="container">
					<section className="about">
						<div className="form text-center">
							<div>
								Wpisz swój adres e-mail, wyślemy Ci szczegółowe informacje.
							</div>
							<Form>
								<Input
									name="email"
									type="email"
									value={email}
									placeholder="e-mail"
									handleChange={this.handleChange}
									handleError={this.handleError}
								/>
							</Form>
						</div>

						<div className="steps">
							{
								aboutEntries.map((entry) => (
									<div className="step text-center" key={entry.title}>
										<div>
											<div className="title">{entry.title}</div>
											<div className="subtitle">{entry.subtitle}</div>
										</div>
										<img src={entry.image} alt="icon" />
									</div>
								))
							}

							<div className="promo text-center">
								<div className="headline">
									<p className="title">tell.well</p>
									<p className="subtitle">fundacja</p>
								</div>
								<div className="content">
									Dzięki Tobie rozmawiają inni.
									<br />
									Każda rozmowa zasila konto naszej fundacji zapewniającej pomoc psychologiczną
									<br />
									dla podpiecznych zaprzyjaźnionych fundacji i instytucji.
									<Link to="/"> Dowiedz się więcej.</Link>
								</div>
							</div>

							<img src={`${process.env.PUBLIC_URL}/static/images/hands.png`} alt="uściśnięte dłonie" />
						</div>
					</section>
				</div>
			</Layout>
		);
	}
}

export default AboutSpec;
