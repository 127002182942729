import React from 'react';
import {BrowserRouter, Switch, Route} from 'react-router-dom';
import axios from 'axios';

import Home from './view/Home';
import Fundation from './view/Fundation';
import About from './view/About';
import AboutSpec from './view/About/Spec';
import Knowledge from './view/Knowledge';
import Contact from './view/Contact';
import Verify from './view/Verify';

axios.interceptors.response.use((response) => response.data);
axios.defaults.headers.Authorization = window.localStorage.getItem('token') ? `Bearer ${window.localStorage.getItem('token')}` : '';

const App = () => (
	<>
		<BrowserRouter>
			<Switch>
				<Route path="/" component={Home} exact />
				<Route path="/fundation" component={Fundation} exact />
				<Route path="/about" component={About} exact />
				<Route path="/about/spec" component={AboutSpec} exact />
				<Route path="/knowledge" component={Knowledge} exact />
				<Route path="/verify/:code" component={Verify} exact />
				<Route path="/contact" component={Contact} exact />
			</Switch>
		</BrowserRouter>
	</>
);

export default App;
