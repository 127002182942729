import React from 'react';
import {Link} from 'react-router-dom';

import Layout from '../../component/Layout';

const aboutEntries = [
	{
		title: 'ZAŁÓŻ KONTO',
		subtitle: (
			<>
				Zarejestruj się jako użytkownik.
				<Link to="/about/spec"> Jesteś specjalistą?</Link>
			</>
		),
		image: `${process.env.PUBLIC_URL}/static/images/account.png`
	},
	{
		title: 'WYPEŁNIJ ANKIETĘ',
		subtitle: '15 pytań które pomagają nam wybrać dla Ciebie najlepszego psychologa (opcjonalnie)',
		image: `${process.env.PUBLIC_URL}/static/images/survey.png`
	},
	{
		title: 'USTAL TERMIN',
		subtitle: 'Możesz rozmawiać od razu (7:00 - 22:00) lub umówić się w dogodnym dla Ciebie terminie.',
		image: `${process.env.PUBLIC_URL}/static/images/date.png`
	},
	{
		title: 'WYBIERZ PSYCHOLOGA',
		subtitle: 'Wybierz psychologa na podstawie wieku, płci, doświadczenia, czy odowiadającej Ci barwie głosu.',
		image: `${process.env.PUBLIC_URL}/static/images/people.png`
	},
	{
		title: 'WYBIERZ METODĘ PŁATNOŚCI',
		subtitle: 'Dodaj kartę, zrób przelew lub zapłać BLIKiem.',
		image: `${process.env.PUBLIC_URL}/static/images/card.png`
	},
	{
		title: 'ROZMAWIAJ',
		subtitle: (
			<>
				Nie potrzebujesz instalować dodatkowych programów.
				<br />
				Rozmawiasz bezpiecznie za pośrednictwem tej strony w formie audio lub/i video.
			</>
		),
		image: `${process.env.PUBLIC_URL}/static/images/call.png`
	}
];

const About = () => (
	<Layout active="about">
		<div className="container">
			<section className="about">
				<div className="motto fit-screen">
					<div>
						<p>
							Rozmowy w jednym miejscu
							<br />
							najprościej jak tylko się da.
						</p>
						<p className="author">
							tell.well
						</p>
					</div>
				</div>

				<div className="steps">
					{
						aboutEntries.map((entry) => (
							<div className="step text-center" key={entry.title}>
								<div>
									<div className="title">{entry.title}</div>
									<div className="subtitle">{entry.subtitle}</div>
								</div>
								<img src={entry.image} alt="icon" />
							</div>
						))
					}

					<div className="promo text-center">
						<div className="headline">
							<p className="title">tell.well</p>
							<p className="subtitle">fundacja</p>
						</div>
						<div className="content">
							Dzięki Tobie rozmawiają inni.
							<br />
							Każda rozmowa zasila konto naszej fundacji zapewniającej pomoc psychologiczną
							<br />
							dla podpiecznych zaprzyjaźnionych fundacji i instytucji.
							<Link to="/"> Dowiedz się więcej.</Link>
						</div>
					</div>

					<img src={`${process.env.PUBLIC_URL}/static/images/hands.png`} alt="uściśnięte dłonie" />
				</div>
			</section>
		</div>
	</Layout>
);

export default About;
