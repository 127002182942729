export const Authorization = {
	Login: {
		REQUEST: 'AUTHORIZATION_LOGIN_REQUEST',
		SUCCESS: 'AUTHORIZATION_LOGIN_SUCCESS',
		FAILURE: 'AUTHORIZATION_LOGIN_FAILURE'
	},
	Logout: {
		REQUEST: 'AUTHORIZATION_LOGOUT_REQUEST',
		SUCCESS: 'AUTHORIZATION_LOGOUT_SUCCESS',
		FAILURE: 'AUTHORIZATION_LOGOUT_FAILURE'
	},
	Check: {
		REQUEST: 'AUTHORIZATION_CHECK_REQUEST',
		SUCCESS: 'AUTHORIZATION_CHECK_SUCCESS',
		FAILURE: 'AUTHORIZATION_CHECK_FAILURE'
	},
	SignIn: {
		REQUEST: 'AUTHORIZATION_SIGNIN_REQUEST',
		SUCCESS: 'AUTHORIZATION_SIGNIN_SUCCESS',
		FAILURE: 'AUTHORIZATION_SIGNIN_FAILURE'
	},
	Register: {
		REQUEST: 'AUTHORIZATION_REGISTER_REQUEST',
		SUCCESS: 'AUTHORIZATION_REGISTER_SUCCESS',
		FAILURE: 'AUTHORIZATION_REGISTER_FAILURE'
	},
	Restore: {
		REQUEST: 'AUTHORIZATION_RESTORE_REQUEST',
		SUCCESS: 'AUTHORIZATION_RESTORE_SUCCESS',
		FAILURE: 'AUTHORIZATION_RESTORE_FAILURE'
	},
	ResendVerification: {
		REQUEST: 'AUTHORIZATION_RESEND_VERIFICATION_REQUEST',
		SUCCESS: 'AUTHORIZATION_RESEND_VERIFICATION_SUCCESS',
		FAILURE: 'AUTHORIZATION_RESEND_VERIFICATION_FAILURE'
	},
	Verify: {
		REQUEST: 'AUTHORIZATION_VERIFY_REQUEST',
		SUCCESS: 'AUTHORIZATION_VERIFY_SUCCESS',
		FAILURE: 'AUTHORIZATION_VERIFY_FAILURE'
	}
};

export const Contact = {
	FormSubmit: {
		REQUEST: 'CONTACT_FORM_SUBMIT_REQUEST',
		SUCCESS: 'CONTACT_FORM_SUBMIT_SUCCESS',
		FAILURE: 'CONTACT_FORM_SUBMIT_FAILURE'
	}
};

export const Notification = {
	Add: 'NOTIFICATION_ADD',
	Remove: 'NOTIFICATION_REMOVE'
};
