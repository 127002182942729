import React from 'react';
import PropTypes from 'prop-types';

import Topbar from '../Topbar';

const Layout = (props) => {
	const {
		active,
		children
	} = props;

	return (
		<>
			<Topbar active={active} />
			<div className="container">
				{children}
			</div>
		</>
	);
};

Layout.propTypes = {
	active: PropTypes.string.isRequired,
	children: PropTypes.node.isRequired
};

export default Layout;
