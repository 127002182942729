import React, {Component} from 'react';
import PropTypes from 'prop-types';

import Layout from '../../component/Layout';
import {handleChange, handleError} from '../../util/common';
import {Form, Input, Textarea} from '../../component/Form';

class Contact extends Component {
	state = {
		name: '',
		email: '',
		content: '',
		refreshError: false
	};

	componentDidUpdate(prevProps) {
		if (!prevProps.formSubmitted && this.props.formSubmitted) {
			this.props.addNotification('success', 'Form submitted');
		}
	}

	handleChange = (name, value) => handleChange(this, name, value);

	handleError = (name, error) => handleError(this, name, error);

	handleSubmit = () => {
		const {formSubmit} = this.props;
		const {
			name,
			email,
			content,
			errors
		} = this.state;

		this.setState({
			refreshError: true
		}, () => {
			this.setState({
				refreshError: false
			});
		});

		if (errors.name || errors.email || errors.content) {
			console.log('fix error in form');

			return false;
		}

		formSubmit({
			name,
			email,
			content
		});

		return true;
	}

	render() {
		const {loading} = this.props;
		const {
			name,
			email,
			content,
			refreshError
		} = this.state;

		return (
			<Layout active="contact">
				<section className="contact fit-screen">
					<div className="row">
						<div className="col-8-12 col-offset-2-12">
							<div className="title">
								<span>KONTAKT</span>
								<img src={`${process.env.PUBLIC_URL}/static/images/contact-icon.svg`} alt="Ikona kontaktu" />
							</div>
							<div className="subtitle">
								napisz do nas
							</div>
							<Form>
								{
									loading && (
										<p>Wysyłanie...</p>
									)
								}
								<Input
									handleChange={this.handleChange}
									handleError={this.handleError}
									name="name"
									value={name}
									placeholder="Imię i nazwisko"
									refreshError={refreshError}
									required
								/>
								<Input
									handleChange={this.handleChange}
									handleError={this.handleError}
									name="email"
									value={email}
									type="email"
									placeholder="E-mail"
									refreshError={refreshError}
									required
								/>
								<Textarea
									handleChange={this.handleChange}
									handleError={this.handleError}
									name="content"
									value={content}
									placeholder="Treść wiadomości"
									refreshError={refreshError}
									required
								/>
								<button
									type="button"
									className="btn small"
									onClick={this.handleSubmit}
								>
									wyślij
								</button>
							</Form>
						</div>
					</div>
				</section>
			</Layout>
		);
	}
}

Contact.propTypes = {
	formSubmit: PropTypes.func.isRequired,
	formSubmitted: PropTypes.bool.isRequired,
	loading: PropTypes.bool.isRequired,
	addNotification: PropTypes.func.isRequired
};

export default Contact;
