import React from 'react';
import PropTypes from 'prop-types';

const Notifications = (props) => {
	const {
		notifications
	} = props;

	return (
		<div className="notifications-box">
			{
				notifications.map((notification) => (
					<div key={notification.id} id={notification.id} />
				))
			}
		</div>
	);
};

Notifications.propTypes = {
	notifications: PropTypes.array.isRequired
};

export default Notifications;
