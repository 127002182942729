import {connect} from 'react-redux';

import Verify from './Verify';
import {formSubmit} from '../../actions/contact';
import {verify} from '../../actions/authorization';

const mapStateToProps = (state, ownProps) => ({
	code: ownProps.match.params.code,
	verified: state.authorization.verified
});

const mapDispatchToProps = (dispatch) => ({
	formSubmit: (data) => dispatch(formSubmit(data)),
	verify: (code) => dispatch(verify(code))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Verify);
