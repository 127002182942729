import {combineReducers} from 'redux';

import authorization from './authorization';
import contact from './contact';
import notification from './notification';

export default combineReducers({
	authorization,
	contact,
	notification
});
